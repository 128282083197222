<template>
  <div class="page-wrapper">

    <section class="hero">
      <div class="content-wrap">
        <div class="data">
          <h1 class="big-title">Envie de relever des nouveaux défis en enseignement ?</h1>
          <p class="regular-text">Explorez les opportunités de carrière dans le réseau d’éducation francophone de la Colombie&nbsp;-&nbsp;Britannique.</p>
          <a href="#mainform" class="button" v-scroll-to:0>Soumettez votre intérêt</a>
        </div>
        <div class="form-wrap" id="mainform" v-if="!formIsSent">
          <h2 class="medium-title">Entrez en contact avec notre équipe</h2>
          <form action="" class="main-form" @submit="saveContactForm">
            <input v-model="contact.nom" name="nom" type="text" placeholder="Prénom et nom *" required>
            <input v-model="contact.courriel" name="courriel" type="email" placeholder="Courriel *" required>
            <div class="input-wrap cv">
              <p>C.V.</p>
              <input
                id="file"
                type="file"
                name="file"
                class="inputfile"
                :class="{'is-saving': isSaving}"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, text/plain, .pdf"
                @change="uploadCv($event)"
              />
            </div>
            <div class="input-wrap">
              <p>Avez-vous un diplôme en enseignement ? *</p>
              <div class="radio-wrap">
                <input v-model="contact.experience" type="radio" name="experience" value="oui">
                <label for="oui">Oui</label>
                <input v-model="contact.experience" type="radio" class="ml" name="experience" value="non">
                <label for="non">Non</label>
              </div>
            </div>
            <textarea v-model="contact.message" name="message" placeholder="Message"></textarea>
            <!-- <div class="input-wrap"> -->
              <div class="checkbox-row">
                <label for="consentement">En cochant cette case, je consens à transmettre mes informations personnelles au CSF et l'autorise à les utiliser à des fins de recrutement.</label>
                <input v-model="contact.consentement" id="consentement" type="checkbox" name="consentement" value="1" required />
              </div>
            <!-- </div> -->
            <p class="note">* Champs obligatoires</p>
            <input id="form-button" type="submit" value="soumettez votre intérêt" class="button submit-form-button" data-track-button :class="{'is-saving': isSaving}">
          </form>
        </div>
        <div class="form-wrap v-center" id="mainform" v-else>
          <h2 class="medium-title">Merci, votre message a été envoyé avec succès.</h2>
          <p class="note is-center">Nous vous répondrons dans les plus bref délais.</p>
        </div>
      </div>
    </section>

    <section class="videos content-wrap">
      <a href="https://www.youtube.com/watch?v=dYHT7RgUow8&t=2s" class="glightbox big-video">
        <div class="video-title">
          <p>10 raisons pour enseigner en français en Colombie&nbsp;-&nbsp;Britannique</p>
        </div>
        <img src="@/assets/img/play.svg" alt="Play" class="play">
        <img src="@/assets/img/video-big.jpg" alt="Play" class="video">
      </a>
      <a href="https://www.youtube.com/watch?v=4moM4UsEHaw" class="glightbox small-video">
        <img src="@/assets/img/video-small-1.png" alt="video" class="video">
        <p class="video-title">Parler français... Pourquoi ?</p>
        <img src="@/assets/img/play-small.svg" alt="play" class="play">
      </a>
      <a href="https://www.youtube.com/watch?v=w55aMuhKdZ8&t=20s" class="glightbox small-video">
        <img src="@/assets/img/video-small-2.png" alt="video" class="video">
        <p class="video-title">Forum fusion 2019 : les jeunes ont la parole</p>
        <img src="@/assets/img/play-small.svg" alt="play" class="play">
      </a>
      <a href="https://www.youtube.com/watch?v=80bsK1SS0Es" class="glightbox small-video">
        <img src="@/assets/img/video-small-3.png" alt="video" class="video">
        <p class="video-title">Le Conseil scolaire francophone en chiffres</p>
        <img src="@/assets/img/play-small.svg" alt="play" class="play">
      </a>
    </section>

    <section class="arguments content-wrap">
      <h3 class="big-title">Une culture francophone qui nous unit</h3>

      <div class="argument-wrap argument-1">
        <div class="img-wrap">
          <img src="@/assets/img/argument-1.jpg" alt="Colombie-Britannique">
        </div>
        <div class="data">
          <h4 class="medium-title">Dans un paysage à couper le souffle</h4>
          <p class="regular-text">Découvrez la diversité des paysages offerts en Colombie-Britannique. Que vous aimiez la ville, l’océan ou la montagne, il ne reste qu’à choisir votre préférence !</p>
        </div>
      </div>

      <div class="argument-wrap argument-2">
        <div class="data">
          <h4 class="medium-title">Via une culture riche et diversifiée</h4>
          <p class="regular-text">Participez au rayonnement de la francophonie dans un milieu minoritaire en profitant d’une culture riche, diversifiée et abondante. Notre organisation gravite au sein d’une communauté francophone dynamique permettant à chaque employé d’atteindre son plein potentiel. La fierté de notre Conseil scolaire, c’est votre passion toujours plus grande pour l’éducation.</p>
        </div>
        <div class="img-wrap">
          <img src="@/assets/img/argument-2.jpg" alt="Colombie-Britannique">
        </div>
      </div>

      <div class="argument-wrap argument-3">
        <div class="img-wrap">
          <img src="@/assets/img/argument-3.jpg" alt="Colombie-Britannique">
        </div>
        <div class="data">
          <h4 class="medium-title">Dans un système scolaire innovant et flexible</h4>
          <p class="regular-text">Partout en province, les écoles du CSF s’adaptent aux attentes et aux besoins des communauté. Par votre expertise, vos idées et votre engagement envers l’éducation, nous repoussons toujours un peu plus les limites de l’innovation.</p>
        </div>
      </div>
    </section>

    <section class="secteurs">
      <h3 class="big-title">Une multitude d’opportunités pour une carrière qui répond à vos besoins</h3>
      <div class="secteurs-wrap content-wrap">
        <div class="secteur">
          <img src="@/assets/img/secteur-1.jpg" alt="Emplois en enseignement">
          <div class="data">
            <h4 class="small-title">Emplois en enseignement</h4>
            <p class="regular-text">Faites une différence en développant chez l’élève la maîtrise de la langue française en milieu minoritaire.</p>
            <a href="https://carriere.csf.bc.ca/emplois-en-enseignement/" target="_blank">Affichez les postes</a>
          </div>
        </div>
        <div class="secteur">
          <img src="@/assets/img/secteur-2.jpg" alt="Emplois en éducation spécialisée et personnel de soutien">
          <div class="data">
            <h4 class="small-title">Emplois en éducation spécialisée et personnel de soutien</h4>
            <p class="regular-text">Offrez un soutien direct et personnalisé aux élèves ayant des besoins particuliers.</p>
            <a href="https://carriere.csf.bc.ca/carriere-education-specialisee/" target="_blank">Affichez les postes</a>
          </div>
        </div>
        <div class="secteur">
          <img src="@/assets/img/secteur-3.jpg" alt="Postes de direction et emplois professionnels">
          <div class="data">
            <h4 class="small-title">Postes de direction et emplois professionnels</h4>
            <p class="regular-text">Jouez un rôle essentiel dans le développement, l’engagement et l’apprentissage des élèves.</p>
            <a href="https://carriere.csf.bc.ca/postes-de-direction-et-emplois-professionnels/" target="_blank">Affichez les postes</a>
          </div>
        </div>
      </div>
      <a href="#mainform" class="button" v-scroll-to:0>Soumettez votre intérêt</a>
    </section>

    <section class="contact">
      <div class="contact-wrap content-wrap">
        <!-- <div class="row directrice">
          <img src="@/assets/img/directrice.png" alt="Kapka Djarova">
          <h3 class="small-title">Kapka Djarova</h3>
          <p class="regular-text">Directrice des ressources humaines</p>
        </div> -->
        <div class="row">
          <h3 class="small-title">Besoin de réponse à vos questions ?</h3>
          <p class="regular-text">Communiquez avec nous pour obtenir de plus amples renseignements sur la carrière en éducation au CSF. </p>
        </div>
        <div class="row">
          <div class="contact-info">
            <img src="@/assets/img/phone.svg" alt="Phone">
            <p class="regular-text">Téléphone : <a href="tel:604 214 2600">604 214-2600</a><br>Numéro sans frais : <a href="tel:18887152200">1-888 715-2200</a></p>
          </div>
          <div class="contact-info">
            <img src="@/assets/img/mail.svg" alt="Mail">
            <p class="regular-text">Courriel : <a href="mailto:carriere@csf.bc.ca">carriere@csf.bc.ca</a></p>
          </div>
          <div class="contact-info">
            <img src="@/assets/img/map.svg" alt="Map">
            <p class="regular-text">100 – 13511 Commerce Parkway,<br>Richmond BC V6V 2J8</p>
          </div>
          <a href="#mainform" class="button" v-scroll-to:0>Soumettez votre intérêt</a>
        </div>
      </div>
    </section>

    <section class="infolettre">
      <img src="@/assets/img/infolettre.svg" alt="Infolettre">
      <h5 class="medium-title">Soyez les premiers au courant en recevant notre bulletin.</h5>
      <div class="form-wrap">

        <form action="https://votrenouvellevie.us18.list-manage.com/subscribe/post?u=37eb37372acd1de9b249a1d5a&amp;id=7502a53d88" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="form-infolettre" target="_blank" >
          <input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL"  placeholder="Entrez votre courriel">
          <input type="submit" value="" name="subscribe" id="mc-embedded-subscribe" class="button button-infolettre">
          <div id="mce-responses" class="clear">
          <div class="response" id="mce-error-response" style="display:none"></div>
          <div class="response" id="mce-success-response" style="display:none"></div>
          </div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
          <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_37eb37372acd1de9b249a1d5a_7502a53d88" tabindex="-1" value=""></div>
          <div class="clear"></div>
        </form>

      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import GLightbox from 'glightbox'
import { storageRef, firebase } from '@/firebase/init'

import ContactsDB from '@/firebase/contacts-db'

export default {
  head() {
    return {
      title: {
        inner: "Carrière en éducation"
      },
      meta: [
        // Global Metas OG
        { name: 'application-name', content: `${this.appTitle}` },
        {
          name: 'description',
          content: `${this.appTitle} Explorez les opportunités de carrière en enseignement dans le réseau d’éducation francophone de la Colombie-Britanique.`,
          id: 'desc'
        },
        // Twitter OG
        { name: 'twitter:title', content: `${this.appTitle}` },
        { name: 'twitter:card', content: 'summary' },
        {
          n: 'twitter:description',
          c: `${this.appTitle} Explorez les opportunités de carrière en enseignement dans le réseau d’éducation francophone de la Colombie-Britanique.`
        },
        // Facebook / Open Graph
        { property: 'og:title', content: `${this.appTitle}` },
        { property: 'og:site_name', content: `${this.appTitle}` },
        {
          property: 'og:description',
          content: `${this.appTitle} Explorez les opportunités de carrière en enseignement dans le réseau d’éducation francophone de la Colombie-Britanique.`
        },
        // Og Image
        {
          p: 'og:image',
          c:
            'https://perspectives.csf.bc.ca/img/og.jpg'
        },
        {
          name: 'twitter:image',
          content: 'https://perspectives.csf.bc.ca/img/twitter-card.jpg'
        }
      ]
    }
  },
  data() {
    return {
      contact: {},
      isSaving: false,
      uploadProgress: 0,
      formIsSent: false
    }
  },
  methods: {
    async uploadCv(event) {

      // Skip if already saving
      if (this.isSaving) {
        return
      }

      let fileName = event.target.files[0].name

      console.log(event.target.files[0])

      // Slugify the fileName
      fileName = this.slugify(fileName)
      console.log(`🖼 name === ${fileName}`)

      this.contact.cvName = fileName

      const uploadTask = storageRef.child(fileName).put(event.target.files[0])

      this.isSaving = true

      // Register three observers:
      // 1. 'state_changed' observer, called any time the state changes
      // 2. Error observer, called on failure
      // 3. Completion observer, called on successful completion
      uploadTask.on(
        'state_changed',
        snapshot => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          this.uploadProgress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.log(`Upload is ${this.uploadProgress} % done`)
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              console.log('Upload is paused')
              break
            case firebase.storage.TaskState.RUNNING: // or 'running'
              console.log('Upload is running')
              break
            default:
              console.log('Default')
          }
        },
        error => {
          // Handle unsuccessful uploads
          console.log(error)
          this.isSaving = false
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
            console.log('File available at', downloadURL)
            this.contact.cv = downloadURL

            this.isSaving = false
          })
        }
      )

    },
    async saveContactForm(e) {
      e.preventDefault()

      // Skip if already saving
      if (this.isSaving) {
        return
      }

      this.isSaving = true

      console.log(this.contact)

      this.contact.consentement = this.contact.consentement ? "je consens à transmettre mes informations personnelles au CSF et l'autorise à les utiliser à des fins de recrutement." : ''

      const contactsDbRef = new ContactsDB()
      await contactsDbRef.create(this.contact)

      this.isSaving = false
      this.contact = {}
      this.formIsSent = true
    },
    slugify(text) {
      return (
        text
          .toString()
          .toLowerCase()
          .replace(/\s+/g, '-') // Replace spaces with -
          // eslint-disable-next-line
          .replace(/[^\w\-]+/g, '') // Remove all non-word chars
          // eslint-disable-next-line
          .replace(/\-\-+/g, '-') // Replace multiple - with single -
          .replace(/^-+/, '') // Trim - from start of text
          .replace(/-+$/, '')
      ) // Trim - from end of text
    }
  },
  mounted() {
    // Activate Video Lightbox
    const lightbox = GLightbox()
    console.log({lightbox})
  },
  computed: mapState('app', ['appTitle'])
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';


</style>
