<template>
  <header class="main-header">
    <div class="content-wrap">
      <img src="@/assets/img/logo.svg" alt="Logo CSF" class="logo">
      <a href="#mainform" class="button" v-scroll-to:0>Soumettez votre intérêt</a>
    </div>
  </header>
</template>

<script>
import firebase from 'firebase/app'
import { mapGetters, mapState } from 'vuex'

export default {
  computed: {
    ...mapGetters('authentication', ['isUserLoggedIn']),
    ...mapState('authentication', ['user']),
    ...mapState('app', ['networkOnLine', 'appTitle', 'appShortTitle'])
  },
  methods: {
    async logout() {
      await firebase.auth().signOut()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

</style>
