<template>
  <footer class="main-footer">
    <div class="footer-wrap content-wrap">
      <p class="regular-text">© Conseil scolaire francophone de la Colombie&nbsp;-&nbsp;Britannique</p>
      <a href="https://carriere.csf.bc.ca/" target="_blank">Consultez le site complet</a>
    </div>
  </footer>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
