import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/storage'
// import 'firebase/analytics'

// The configuration below is not sensitive data. You can serenely add your config here
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCOqgs-3GWwTJKwkW2D4lfkjA3e5x8UJxA',
  authDomain: 'csf-ca.firebaseapp.com',
  databaseURL: 'https://csf-ca.firebaseio.com',
  projectId: 'csf-ca',
  storageBucket: 'csf-ca.appspot.com',
  messagingSenderId: '683067728349',
  appId: '1:683067728349:web:8e826121712a80d326d634',
  measurementId: 'G-SB2TQ10Q39'
}

firebase.initializeApp(firebaseConfig)

// 🔥firebase utils
const storageRef = firebase.storage().ref()
// const analytics = firebase.analytics()

export { storageRef, firebase }
