<template>
  <div id="app">

    <nav-bar ref="$nav"></nav-bar>

    <div class="main-wrapper">
      <router-view />
    </div>

    <Footer />

    <!-- <new-content-available-toastr
      v-if="newContentAvailable"
      class="new-content-available-toastr"
      :refreshing-app="refreshingApp"
      @refresh="serviceWorkerSkipWaiting"
    ></new-content-available-toastr>
    <apple-add-to-home-screen-modal
      v-if="showAddToHomeScreenModalForApple"
      class="apple-add-to-home-screen-modal"
      @close="closeAddToHomeScreenModalForApple(false)"
    >
    </apple-add-to-home-screen-modal> -->
  </div>
</template>
<script>
import NavBar from '@/components/NavBar'
import Footer from '@/components/Footer'
// import NewContentAvailableToastr from '@/components/NewContentAvailableToastr'
// import AppleAddToHomeScreenModal from '@/components/AppleAddToHomeScreenModal'
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  components: { NavBar, Footer },
  computed: {
    ...mapGetters('app', ['newContentAvailable']),
    ...mapState('app', ['showAddToHomeScreenModalForApple', 'refreshingApp'])
  },
  methods: {
    toogleNavBarStatus() {
      const st = document.documentElement.scrollTop

      if (!document.querySelector('.main-header')) {
        return
      }

      if( st >= 40 ) {
        document.querySelector('.main-header').classList.add('sticky')
      }else{
        document.querySelector('.main-header').classList.remove('sticky')
      }
    },
    ...mapActions('app', [
      'closeAddToHomeScreenModalForApple',
      'serviceWorkerSkipWaiting'
    ])
  },
  created() {

    // NavBar Follow
    this.toogleNavBarStatus()
    window.addEventListener('scroll', () => {
      this.toogleNavBarStatus()
    })
  }
}
</script>

<style lang="scss">
@import '@/theme/variables.scss';
@import '@/theme/base.scss';
@import '@/theme/typography.scss';
@import '@/theme/buttons.scss';
@import '@/theme/form.scss';
@import '@/theme/vendor.scss';
@import '@/theme/site-modules.scss';
</style>
